// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
//= require rails-ujs
//= require jquery
//= require jquery_ujs
//= require turbolinks
//= require bootstrap-sprockets
//= require jquery-ui
//= require ckeditor/init
//= require ./klaro/config.js
//= require ./klaro.js
import "bootstrap"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(function () {
  $(".alert-message").each(function () {
    if ($(this).text().trim().length) {
      $(this).parent().show()
      if ($(this).parent().hasClass("alert-success")) {
        $(this).parent().delay(2000).fadeOut(1000)
      }
      if ($(this).parent().hasClass("alert-warning")) {
        $(this).parent().delay(2000).fadeOut(1000)
      }
    }
  })
  if ($(".ckeditor-5").length) {
    ClassicEditor.create(document.querySelector(".ckeditor-5"), {
      language: "de",
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: "h1",
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: "h2",
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
          {
            model: "heading3",
            view: "h3",
            title: "Heading 3",
            class: "ck-heading_heading3",
          },
          {
            model: "heading4",
            view: "h4",
            title: "Heading 4",
            class: "ck-heading_heading4",
          },
        ],
      },
    })
      .then((editor) => {
        console.log(editor)
      })
      .catch((error) => {
        console.error(error)
      })
  }
})
